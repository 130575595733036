.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #21386D;
  background-color: #000;
  min-height: 4vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + .5vmin);
  color: #fff;
}

.App-body {
	background-color: #fff;
	xbackground: url(./images/StockSnap_E1I2HFY0CN.jpg) no-repeat center center fixed;
  min-height: 85vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: 000;
}

.App-footer {
  background-color: #d4d4d4;
  background-color: #ddd;
  min-height: 11vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  font-size: calc(10px + .8vmin);
  color: #fff;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.site-footer {
	padding-top: 40px;
	color: #777;
	font-size: 16px;
}
